import { useState } from 'react';
import type { ReactNode } from 'react';

import classnames from 'classnames';

import BpkSelectableChip from '@skyscanner/backpack-web/bpk-component-chip';
import BpkMobileScrollContainer from '@skyscanner/backpack-web/bpk-component-mobile-scroll-container';

import CardStack from '../CardStack';
import ThemeCard from '../ThemeCard';

import type { CardStackWithThemeCardProps } from '@skyscanner-internal/falcon-common-types/types/CardStackWithThemeCardProps';
import type { ThemeCardProps } from '@skyscanner-internal/falcon-common-types/types/ThemeCardProps';

import STYLES from './CardStackWithThemeCard.module.scss';

type SelectedChipsProps = {
  activeChipIndex: number;
  setActiveChipIndex: (index: number) => void;
  chips: string[];
};
type CardStackWithThemeCardPropsWithExtraProps = CardStackWithThemeCardProps & {
  callToAction?: ReactNode;
};

const SelectedChips = ({
  activeChipIndex,
  chips,
  setActiveChipIndex,
}: SelectedChipsProps) => {
  if (!chips || !chips.length) {
    return null;
  }

  return (
    <div className={STYLES.SelectableChip}>
      <BpkMobileScrollContainer>
        {chips.map(
          (chip, index) =>
            chip && (
              <div key={chip} className={STYLES.SelectableChip__item}>
                <BpkSelectableChip
                  accessibilityLabel={chip}
                  selected={index === activeChipIndex}
                  onClick={() => setActiveChipIndex(index)}
                >
                  {chip}
                </BpkSelectableChip>
              </div>
            ),
        )}
      </BpkMobileScrollContainer>
    </div>
  );
};

const CardStackWithThemeCard = ({
  callToAction = null,
  cards,
  disclaimer,
  expandProps = undefined,
  grouping = false,
  imageConfig,
  onCardClick,
  subtitle,
  title,
}: CardStackWithThemeCardPropsWithExtraProps) => {
  const hasCards = !!cards?.length;
  const [activeChipIndex, setActiveChipIndex] = useState(0);
  if (!hasCards) {
    return null;
  }
  const chips = grouping
    ? Array.from(
        new Set(
          cards
            .map((item) => item.groupKey)
            .filter((key): key is string => !!key),
        ),
      )
    : [];
  const renderCards = chips?.length
    ? cards.filter((card) => card.groupKey === chips[activeChipIndex])
    : cards;
  return (
    <div className={STYLES.CardStackWithThemeCard}>
      <CardStack
        title={title}
        subtitle={subtitle}
        disclaimer={disclaimer}
        callToAction={
          // Hide ExpandableControl if there are less cards than the maxToShowWhenCollapse
          !expandProps || renderCards.length > expandProps.maxToShowWhenCollapse
            ? callToAction
            : null
        }
      >
        <SelectedChips
          chips={chips}
          activeChipIndex={activeChipIndex}
          setActiveChipIndex={setActiveChipIndex}
        />
        <div className={STYLES.CardStack}>
          {(() => {
            let indexInChip = -1;
            return cards.map((card, index) => {
              indexInChip = isCurrentChip(
                grouping,
                card,
                chips[activeChipIndex],
              )
                ? indexInChip + 1
                : indexInChip;
              return (
                <div
                  data-testid={`themeCardGourp${index}`}
                  className={classnames(STYLES.CardStack__card, STYLES.card, {
                    [STYLES.CardStack__hide]:
                      !isCurrentChip(grouping, card, chips[activeChipIndex]) ||
                      isHiddenByCollapse(
                        grouping ? indexInChip : index,
                        expandProps,
                      ),
                  })}
                >
                  <ThemeCard
                    key={card.title}
                    {...card}
                    imageConfig={imageConfig}
                    onClick={onCardClick && (() => onCardClick(card))}
                  />
                </div>
              );
            });
          })()}
        </div>
      </CardStack>
    </div>
  );
};

const isCurrentChip = (
  grouping: boolean,
  card: ThemeCardProps,
  activeChip: string,
) => !grouping || card.groupKey === activeChip;

const isHiddenByCollapse = (
  index: number,
  expandProps?: {
    maxToShowWhenCollapse: number;
    isExpand: boolean;
  },
) =>
  expandProps?.isExpand === false && index >= expandProps.maxToShowWhenCollapse;

export default CardStackWithThemeCard;

import { useEffect, useState } from 'react';

import loadable from '@loadable/component';

import BpkBreakpoint, {
  BREAKPOINTS,
} from '@skyscanner/backpack-web/bpk-component-breakpoint';
import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import {
  withButtonAlignment,
  withRtlSupport,
} from '@skyscanner/backpack-web/bpk-component-icon';
import LongArrowDownIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/long-arrow-down';
import LongArrowIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/long-arrow-right';
import LongArrowUpIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/long-arrow-up';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import {
  ACTION_TYPE,
  COMPONENT_NAME,
  COMPONENT_ACTION,
  LOAD_STATUS,
  CAR_TYPE,
} from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import CardRailWithThemeCard from '../CardRailWithThemeCard';
import CardStackWithThemeCard from '../CardStackWithThemeCard';

import type {
  CardDealsProps,
  ViewAllProps,
  ExpandableProps,
} from '@skyscanner-internal/falcon-common-types/types/CardDealsProps';
import type { ThemeCardProps } from '@skyscanner-internal/falcon-common-types/types/ThemeCardProps';

import STYLES from './CardDeals.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);
const LongArrowRight = withButtonAlignment(withRtlSupport(LongArrowIcon));
const AlignedDownArrow = withButtonAlignment(withRtlSupport(LongArrowDownIcon));
const AlignedUpArrow = withButtonAlignment(withRtlSupport(LongArrowUpIcon));
const DESKTOP_DEFAULT_LIMIT = 6;
const MOBILE_DEFAULT_LIMIT = 3;

const ViewAll = ({ redirectUrl, text }: ViewAllProps) => {
  const onViewAllClick = () => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.CAR_HIRE_INDICATIVE_DEALS,
      component_action:
        COMPONENT_ACTION.CAR_HIRE_INDICATIVE_DEALS.VIEW_ALL_DEALS_BUTTON,
    });
  };
  return (
    <div className={STYLES.ViewAll}>
      <BpkButton
        link
        className={STYLES.ViewAll__button}
        href={redirectUrl}
        rel="nofollow"
        onClick={onViewAllClick}
      >
        <BpkText textStyle={TEXT_STYLES.label1}>{text}</BpkText>
        <div className={STYLES.ViewAll__buttonArrow}>
          <LongArrowRight />
        </div>
      </BpkButton>
    </div>
  );
};
type ExpandableControlProps = ExpandableProps & {
  isExpand: boolean;
  setExpand: (isExpand: boolean) => void;
};
const ExpandableControl = ({
  collapseText,
  expandText,
  isExpand,
  setExpand,
}: ExpandableControlProps) => {
  const onShowMoreClick = () => {
    setExpand(!isExpand);
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.CAR_HIRE_INDICATIVE_DEALS,
      component_action:
        COMPONENT_ACTION.CAR_HIRE_INDICATIVE_DEALS.SHOW_MORE_BUTTON_CLICKED,
    });
  };
  return (
    <div className={STYLES.ViewAll}>
      <BpkButton
        link
        className={STYLES.ViewAll__button}
        onClick={onShowMoreClick}
      >
        <BpkText textStyle={TEXT_STYLES.label1}>
          {isExpand ? collapseText : expandText}
        </BpkText>
        {isExpand ? <AlignedUpArrow /> : <AlignedDownArrow />}
      </BpkButton>
    </div>
  );
};

const CardDeals = ({
  cards,
  disclaimer,
  expandable,
  grouping = false,
  imageConfig,
  isMobile,
  scroll = { desktop: false, mobile: false },
  subtitle,
  title,
  trackingProps,
  viewAll,
}: CardDealsProps) => {
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name:
        trackingProps?.componentName ||
        COMPONENT_NAME.CAR_HIRE_INDICATIVE_DEALS,
      load_status: cards?.length ? LOAD_STATUS.LOADED : LOAD_STATUS.INVALID,
    });
  }, [cards]);
  if (!cards?.length) {
    return null;
  }
  const onCardClick = (card: ThemeCardProps) => {
    if (card?.trackingData) {
      const { bags, carClass, carType, seats, subClass } =
        card?.trackingData || {};
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_CLICKED,
        component_name:
          trackingProps?.componentName ||
          COMPONENT_NAME.CAR_HIRE_INDICATIVE_DEALS,
        component_action:
          COMPONENT_ACTION.CAR_HIRE_INDICATIVE_DEALS.DEAL_CLICKED,
        car_deal: {
          car: {
            class: carClass ? carClass.toUpperCase() : undefined,
            sub_class: subClass
              ? CAR_TYPE[subClass.toUpperCase() as keyof typeof CAR_TYPE]
              : undefined,
            car_type: carType?.toUpperCase(),
          },
          is_most_popular_label: !!card.topBadges?.length,
          seats: seats ? seats.toString() : '',
          bags: bags ? bags.toString() : '',
          cheapest_price_local: {
            currency: card.priceRaw?.currencyCode,
            amount: Number(card.priceRaw?.amount),
            unit:
              card.priceRaw?.unit?.toString().replace('UNIT_', '') || 'CENTI',
          },
        },
      });
    }
  };

  const expandableControl = expandable ? (
    <ExpandableControl
      {...expandable}
      isExpand={expand}
      setExpand={setExpand}
    />
  ) : null;
  const viewAllAction = viewAll ? <ViewAll {...viewAll} /> : null;

  const CardRail = (
    <CardRailWithThemeCard
      disclaimer={disclaimer}
      imageConfig={imageConfig}
      subtitle={subtitle}
      onCardClick={onCardClick}
      title={title}
      cards={cards}
      callToAction={viewAllAction}
      grouping={grouping}
    />
  );
  const CardStack = (
    <CardStackWithThemeCard
      disclaimer={disclaimer}
      imageConfig={imageConfig}
      subtitle={subtitle}
      title={title}
      onCardClick={onCardClick}
      cards={
        expandable || grouping
          ? cards
          : cards.slice(
              0,
              isMobile ? MOBILE_DEFAULT_LIMIT : DESKTOP_DEFAULT_LIMIT,
            )
      }
      callToAction={expandableControl || viewAllAction}
      grouping={grouping}
      expandProps={
        expandable
          ? {
              isExpand: expand,
              maxToShowWhenCollapse:
                expandable?.maxToShowWhenCollapse || cards.length,
            }
          : undefined
      }
    />
  );

  return (
    <BpkBreakpoint query={BREAKPOINTS.MOBILE} matchSSR={isMobile === true}>
      {(mobile: boolean) => {
        if (
          (mobile && scroll.mobile === true) ||
          (!mobile && scroll.desktop === true)
        ) {
          return <div className={STYLES.CardDeals}>{CardRail}</div>;
        }
        return <div className={STYLES.CardDeals}>{CardStack}</div>;
      }}
    </BpkBreakpoint>
  );
};

export default (props: CardDealsProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.CAR_HIRE_INDICATIVE_DEALS,
      })
    }
  >
    <CardDeals {...props} />
  </IntersectionObserverWrapper>
);

import type { ReactNode, PropsWithChildren } from 'react';

import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import FooterBlurb from '../common/FooterBlurb/index';

import STYLES from './CardStack.module.scss';

type CardStackProps = PropsWithChildren<{
  title?: string;
  subtitle?: string;
  children: JSX.Element[];
  callToAction?: ReactNode;
  disclaimer?: string;
}>;

const CardStack = ({
  callToAction = null,
  children = [],
  disclaimer = undefined,
  subtitle = undefined,
  title = undefined,
}: CardStackProps) => {
  if (children.length === 0) {
    return null;
  }
  return (
    <div className={STYLES.CardStack}>
      {title && (
        <div className={STYLES.header}>
          <BpkSectionHeader title={title} description={subtitle} />
        </div>
      )}
      <div className={STYLES.CardStack__content}>{children}</div>
      {callToAction}
      {disclaimer && (
        <div className={STYLES.CardStack__disclaimer}>
          <FooterBlurb disclaimerCopy={disclaimer} />
        </div>
      )}
    </div>
  );
};

export default CardStack;

import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import BpkSelectableChip from '@skyscanner/backpack-web/bpk-component-chip';
import BpkMobileScrollContainer from '@skyscanner/backpack-web/bpk-component-mobile-scroll-container';
import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import CardRail from '../CardRail';
import ThemeCard from '../ThemeCard';

import type { CardRailWithThemeCardProps } from '@skyscanner-internal/falcon-common-types/types/CardRailWithThemeCardProps';

import STYLES from './CardRailWithThemeCard.module.scss';

type CardRailWithThemeCardPropsWithExtraProps = CardRailWithThemeCardProps & {
  callToAction?: ReactNode;
};
type SelectedChipsProps = {
  activeChipIndex: number;
  setActiveChipIndex: (index: number) => void;
  chips: string[];
};
const SelectedChips = ({
  activeChipIndex,
  chips,
  setActiveChipIndex,
}: SelectedChipsProps) => {
  if (!chips || !chips.length) {
    return null;
  }

  return (
    <div className={STYLES.SelectableChip}>
      <BpkMobileScrollContainer>
        {chips.map(
          (chip, index) =>
            chip && (
              <div className={STYLES.SelectableChip__item}>
                <BpkSelectableChip
                  key={chip}
                  accessibilityLabel={chip}
                  selected={index === activeChipIndex}
                  onClick={() => setActiveChipIndex(index)}
                >
                  {chip}
                </BpkSelectableChip>
              </div>
            ),
        )}
      </BpkMobileScrollContainer>
    </div>
  );
};

const CardRailWithThemeCard = ({
  callToAction = null,
  cards,
  disclaimer,
  grouping,
  imageConfig,
  onCardClick,
  subtitle,
  title,
}: CardRailWithThemeCardPropsWithExtraProps) => {
  const [activeChipIndex, setActiveChipIndex] = useState(0);
  const [renderCards, setRenderCards] = useState(cards);
  const chips = grouping
    ? Array.from(
        new Set(
          cards
            .map((item) => item.groupKey)
            .filter((key): key is string => !!key),
        ),
      )
    : [];
  useEffect(() => {
    if (grouping) {
      setRenderCards(
        cards.filter((card) => chips[activeChipIndex] === card.groupKey),
      );
    }
  }, [activeChipIndex]);

  if (!cards.length) {
    return null;
  }

  return (
    <div className={STYLES.CardRailWithThemeCard}>
      <div className={STYLES.CardRailWithThemeCard__header}>
        <BpkSectionHeader title={title} description={subtitle} />
      </div>

      <SelectedChips
        chips={chips}
        activeChipIndex={activeChipIndex}
        setActiveChipIndex={setActiveChipIndex}
      />
      <CardRail disclaimer={disclaimer} callToAction={callToAction}>
        {renderCards.map((card, index) => (
          <div
            key={card.title}
            data-testid={`themeCardGroup${index}`}
            className={String(STYLES.CardRail__card)}
          >
            <ThemeCard
              {...card}
              imageConfig={imageConfig}
              onClick={onCardClick && (() => onCardClick(card))}
            />
          </div>
        ))}
      </CardRail>
    </div>
  );
};

export default CardRailWithThemeCard;
